<template>
  <v-snackbar
    bottom
    :value="isToastVisible"
    :timeout="toast.timeout"
    :color="toast.color"
    @input="updateStoreIfNotVisible($event)"
  >
    {{ toast.message }}
    <v-btn v-if="toast.timeout === 0" text color="white" @click="hideToast">
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('app', ['toast', 'isToastVisible']),
  },
  methods: {
    ...mapActions('app', ['hideToast']),
    updateStoreIfNotVisible(isVisible) {
      if (!isVisible) {
        this.hideToast()
      }
    },
  },
}
</script>
