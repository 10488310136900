<template>
  <div class="d-flex align-center pointer">
    <img src="@/assets/images/logo.png" alt="ActivePrime logo" height="28px" />
    <div class="d-flex flex-column ml-2">
      <div class="text-body-2 font-weight-bold primary--text">ActivePrime</div>
      <div v-if="feature" class="mt-n1">
        <div class="primary--text" style="font-size: 10px">
          {{ feature }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    feature: {
      type: String,
      default: 'CleanData',
    },
  },
}
</script>
